<template>
  <div>
    <b-card bg-variant="light" header="Infraestructura de Servicios" header-bg-variant="primary" header-text-variant="white" align="center" >
        
        <b-form @submit="modalEnviar" @reset="cancelar" align="left" id="formularioResiduos">
            <b-form-row>
                <b-col cols="12" md="4">
                    <b-form-group
                        id="group-acueducto"
                        label="Tiene servicio de acueducto"
                        label-for="acueducto"
                    >
                        <b-form-select 
                            id="acueducto" 
                            v-model="form.acueducto" 
                            class="mb-3" 
                            required 
                            :disabled="verDetalles?true:false"
                        >
                            <b-form-select-option value="0">No</b-form-select-option>
                            <b-form-select-option value="1">Si</b-form-select-option>
                        </b-form-select>
                    </b-form-group>
                </b-col>
                
                <b-col cols="12" md="4">
                    <b-form-group
                        id="group-uso_acueducto"
                        label="Uso del acueducto"
                        label-for="uso_acueducto"
                    >
                        <b-form-select 
                            id="uso_acueducto" 
                            v-model="form.uso_acueducto" 
                            class="mb-3" 
                            required 
                            :disabled="verDetalles?true:false"
                        >
                            <b-form-select-option value="0">Residencial</b-form-select-option>
                            <b-form-select-option value="1">Comercial</b-form-select-option>
                            <b-form-select-option value="2">Industrial</b-form-select-option>
                        </b-form-select>
                    </b-form-group>
                </b-col>

                 <b-col cols="12" md="4">
                    <b-form-group
                        id="group-pozo_concesion"
                        label="Tiene servicio pozo/concesion de aguas"
                        label-for="pozo_concesion"
                    >
                        <b-form-select 
                            id="pozo_concesion" 
                            v-model="form.pozo_concesion" 
                            class="mb-3" 
                            required 
                            :disabled="verDetalles?true:false"
                        >
                            <b-form-select-option value="0">No</b-form-select-option>
                            <b-form-select-option value="1">Si</b-form-select-option>
                        </b-form-select>
                    </b-form-group>
                </b-col>

                 <b-col cols="12" md="4">
                    <b-form-group
                        id="group-archivo_pozo"
                        label="Permiso consesión de aguas"
                        label-for="archivo_pozo"
                    >
                        <b-form-file
                            id="archivo_pozo"
                            :state="Boolean(form.archivo_pozo)"
                            placeholder="Elige un archivo"
                            browseText="Buscar"
                            accept="application/pdf"
                            @change="imagenArchivoPozo"
                            v-if="!cargadoArchivoPozo"
                            :disabled="verDetalles?true:false"
                        ></b-form-file>

                        <b-button block variant="primary" class="mr-2" v-if="cargadoArchivoPozo" @click="descargarPDF(form.archivo_pozo, 'Pozo')">
                            Descargar
                        </b-button>
                    </b-form-group>

                </b-col>

                <b-col cols="12" md="4">
                    <b-form-group
                        id="group-compra_bloque"
                        label="Tiene compra en bloque de agua"
                        label-for="compra_bloque"
                    >
                        <b-form-select 
                            id="compra_bloque" 
                            v-model="form.compra_bloque" 
                            class="mb-3" 
                            required 
                            :disabled="verDetalles?true:false"
                        >
                            <b-form-select-option value="0">No</b-form-select-option>
                            <b-form-select-option value="1">Si</b-form-select-option>
                        </b-form-select>
                    </b-form-group>
                </b-col>
                
                <b-col cols="12" md="4" v-if="form.compra_bloque==1">
                    <b-form-group
                        id="group-id_proveedor_agua"
                        label="Identificador del proveedor de agua en bloque"
                        label-for="id_proveedor_agua"
                    >
                        <b-form-select 
                            id="id_proveedor_agua" 
                            v-model="form.id_proveedor_agua" 
                            class="mb-3" 
                            :required="form.compra_bloque==1"
                            :options="mapearProveedores"
                            :disabled="verDetalles?true:false"
                        >
                        </b-form-select>
                    </b-form-group>
                </b-col>

                <b-col cols="12" class="text-right">
                    <b-button type="submit" variant="primary" class="mr-2" :disabled="verDetalles?true:false">
                        Guardar
                        </b-button>
                    <b-button type="reset" >Cancelar</b-button>
                </b-col>
            </b-form-row>

        </b-form>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import {encontrarError} from '../../../contantesErrores';
  export default {
      name: 'InfraServiVigilanciaControl',
    data() {
      return {
        editar:false,
        cargadoArchivoPozo: false,
        form: {
            id: null,
            acueducto: null,
            uso_acueducto:null,
            pozo_concesion: null,
            resolucion_car_pozo: null,
            archivo_pozo: null,
            compra_bloque: null,
            id_proveedor_agua:null
        },
      }
    },
    async mounted(){
        if(this.visitaEnGestion.id){
            this.form.id=this.visitaEnGestion.id
            await this.cargarInfraServi(this.visitaEnGestion.id)
        }
        try{
            await this.cargarProveedores()
        }catch(e){
            encontrarError(this, e.data)
        }
    },
    computed:{
        ...mapState('gestionarVisitas', ['verDetalles','visitaEnGestion']),
        ...mapState('gestionarVisitasVigilanciaControl', ['resultInfraServi', 'infraServiCargada' ]),
        ...mapState('consultaEmpresas', ['proveedoresServicio' ]),

        mapearProveedores(){
            let mapeadas = this.proveedoresServicio.map(item=>{
                let proveedor={
                    value: item.id_prov,
                    text: item.nombre
                }
                return proveedor
            })
            return mapeadas
        },
    },
    methods: {
        ...mapActions(['activarLoading']),
        ...mapActions('gestionarVisitasVigilanciaControl', ['crearInfraServi', 'cargarInfraServiById', 'editarInfraServi']),
        ...mapActions('consultaEmpresas', ['cargarProveedores']),

        async cargarInfraServi(id){
            this.activarLoading(true)
            try{
                await this.cargarInfraServiById(id)
                console.log('esto es lo cargado', this.infraServiCargada[0])
                if(this.infraServiCargada.length>0){
                    this.editar=true
                    this.form=this.infraServiCargada[0]
                    this.cargadoArchivoPozo =  this.form.archivo_pozo?true:false
                }
                this.activarLoading(false)
            }catch(e){
                encontrarError(this, e.data)
            }
        },

        modalEnviar(event){
            event.preventDefault()
             this.$bvModal.msgBoxConfirm('Se van a guardar los datos de Infraestructura y Servicios, ¿Desea continuar?.', {
                title: 'Por favor confirme',
                size: 'md',
                buttonSize: 'sm',
                okVariant: 'primary',
                okTitle: 'Continuar',
                cancelTitle: 'Cancelar',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
                })
                .then(value => {
                    if(value){
                        this.onSubmit()
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        },

        async onSubmit() {
            this.activarLoading(true)

            let dataGuardar = {
                infra: this.form
            }

             try{
                 if(this.editar){
                     await this.editarInfraServi(dataGuardar)
                 }else{
                     await this.crearInfraServi(dataGuardar)
                 }
                if(this.resultInfraServi.data.status==200){
                    this.activarLoading(false)
                    this.modalOk()
                }
            }catch(e){
                this.activarLoading(false)
                encontrarError(this, e.data)
            }
        },

        modalOk(){
            this.$bvModal.msgBoxOk('Los Datos fueron guardados correctamente.', {
                title: 'Confirmación',
                size: 'md',
                buttonSize: 'md',
                okVariant: 'success',
                headerClass: 'p-2 border-bottom-0',
                footerClass: 'p-2 border-top-0',
                centered: true
                })
                .then(() => {
                    // this.$router.push({name: 'gestionVisita'})
                    this.cargarInfraServi(this.visitaEnGestion.id)
                })
                .catch(err => {
                    console.log(err)
                })
        },

        imagenArchivoPozo(e){
            let archivo = e?e.target.files[0]:null
            this.crearBase64(archivo)
        },

        crearBase64(file){
            if(file){
                let reader = new FileReader();
                reader.onload=(e)=>{
                    let archivo = e.target.result
                    let base = archivo.split('base64,')
                    let pdf = base[1]

                    this.form.archivo_pozo = pdf;
                }
                reader.readAsDataURL(file)
            }else{
                this.form.archivo_pozo = null;
            }
        },
        
        cancelar(){
            this.$bvModal.msgBoxOk('Se perderán los datos sin guardar.', {
                title: 'Cancelar',
                size: 'md',
                buttonSize: 'md',
                okVariant: 'success',
                headerClass: 'p-2 border-bottom-0',
                footerClass: 'p-2 border-top-0',
                centered: true
                })
                .then(() => {
                    this.$router.push({name: 'gestionarVisitaVigilanciaControl'})
                })
                .catch(err => {
                    console.log(err)
                })
        }, 

        descargarPDF(pdf, nombre) {
            const linkSource = `data:application/pdf;base64,${pdf}`;
            const downloadLink = document.createElement("a");
            const fileName = `licencia_${nombre}.pdf`;
        
            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
        },
        
    }
  }
</script>

<style scoped>
    
</style>